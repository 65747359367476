import React from 'react';

import { format } from 'date-fns';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { brandColors } from '@/constants/BrandColors';
import ChartGradients from '@/constants/ChartGradients';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { ChartDataModel } from '@/models/Metrics';

import Loading from '../utility/Loading';

const LineChartComponent = <T,>({
  data,
  color,
  height,
  suffix,
  campaignStartDate,
}: {
  data?: ChartDataModel<T>;
  color?: string;
  xAxisKeyName?: string;
  suffix?: string;
  height?: number;
  campaignStartDate?: string;
}) => {
  const brandColor = color
    ? brandColors[color]
    : data && data.color[0]
      ? brandColors[`${data.color[0]}-color`]
      : brandColors['default-color'];

  if (data) {
    const { name, color, key } = data;
    if (name.length !== color.length || color.length !== key.length) {
      throw new Error('The lengths of name, color, and key arrays must be the same.');
    }
  }

  const handleTooltip = (event: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (event.payload && event.payload[0]) {
      return (
        <div className="graph-tooltip text-center">
          {event.payload.map((item, index: number) => (
            <div key={index} className="d-flex">
              <p className="small pt0 pl8 text-left">
                {`${item.name}: ${numberFormatter(Number(item.value))}`}
                {suffix && suffix}
              </p>
            </div>
          ))}
          <p className="small text-faded">{format(new Date(event.payload[0].payload.date), 'dd/MM/yy')}</p>
        </div>
      );
    }
  };

  if (!data) {
    return (
      <div data-testid="dashboard-line-chart">
        <div className="centered-loading mt48 mb48" data-testid="pie-loading">
          <Loading size="small" />
        </div>
      </div>
    );
  }

  if (!data.data.length) {
    return (
      <div data-testid="dashboard-line-chart">
        <p className="small">No Data</p>
      </div>
    );
  }

  return (
    <div data-testid="dashboard-line-chart">
      <ChartGradients />
      {data && (
        <ResponsiveContainer width="100%" height={height || 250}>
          <LineChart className="line-chart" data={data.data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <CartesianGrid stroke="#383838" vertical={false} />
            <XAxis dataKey="date" tickFormatter={(a) => format(new Date(a), 'dd/MM/yy')} />
            <YAxis
              yAxisId="left"
              type="number"
              tickCount={5}
              domain={['dataMin', 'auto']}
              allowDecimals={false}
              tickFormatter={(a) => numberFormatter(a).toString()}
            />
            <YAxis
              yAxisId="right"
              type="number"
              tickCount={5}
              orientation="right"
              domain={['dataMin', 'auto']}
              tickFormatter={(a) => numberFormatter(a).toString()}
              allowDecimals={false}
            />
            {data.key.map((key, index) => (
              <Line
                key={index}
                yAxisId={index % 2 ? 'right' : 'left'}
                type="monotone"
                dot={false}
                name={data.name[index]}
                strokeWidth={2}
                dataKey={key}
                stroke={`url(#line-gradient-${data.color[index] ? data.color[index] : 'default'})`}
              />
            ))}
            <Tooltip content={(event) => handleTooltip(event)} />
            <Legend verticalAlign="bottom" height={40} iconType="square" />
            {data.data &&
              data.key.map((key, index) => {
                const allSame = data.data.every((val, _, arr) => val[key as keyof T] === arr[0][key as keyof T]);

                return (
                  allSame && (
                    <ReferenceLine
                      key={key}
                      yAxisId="left"
                      y={Number(data.data[data.data.length - 1][key as keyof T]) || 0}
                      stroke={brandColor && brandColor[index] ? brandColor[index] : '#05befe'}
                      strokeWidth={2}
                    />
                  )
                );
              })}
            {campaignStartDate && (
              <ReferenceLine
                yAxisId="left"
                x={campaignStartDate}
                stroke="#99989f"
                strokeDasharray="3 3"
                strokeWidth={1}
                label={{ value: 'Campaign Launched', position: 'insideTopLeft', fill: '#99989f' }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default LineChartComponent;
